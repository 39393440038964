import React, { useEffect, useState } from "react";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Polygon from "@arcgis/core/geometry/Polygon";
import PolygonSymbol3D from "@arcgis/core/symbols/PolygonSymbol3D";
import ExtrudeSymbol3DLayer from "@arcgis/core/symbols/ExtrudeSymbol3DLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import "@arcgis/core/assets/esri/themes/light/main.css";
import axios from "./axios";

const EsriMap = () => {
  const [buildings, setBuildings] = useState([]);
  const [accessData, setAccessData] = useState(null);

  const getData = async () => {
    try {
      const res = await axios.get("/eharta-access/all");
      const responseData = res?.data?.data || res; // Ensure consistent data access
      setAccessData(responseData?.access);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("object", accessData)
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (accessData) {
      axios
        .get("/building/all")
        .then((res) => {
          const responseData = res?.data?.data || res; // Ensure consistent data access
          setBuildings(responseData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessData]);

  useEffect(() => {
    if (accessData) {
      const map = new Map({
        basemap: "topo-vector",
        ground: "world-elevation",
      });

      const view = new SceneView({
        container: "viewDiv",
        qualityProfile: "high",
        map: map,
        camera: {
          position: [21.146644, 42.644623, 1590],
          tilt: 60,
        },
      });

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      function addBuildingWithSpaces(building) {
        const {
          buildingName,
          convertedObjects,
          convertedParcels,
          floorsHeight,
          absoluteQuotas,
          heightFloors,
          colors,
        } = building;
        const sliceHeight = 0.2; // Total slice height

        // Add 2D outline with dynamic width
        const outlinePolygon = new Polygon({
          rings: convertedParcels,
          spatialReference: { wkid: 4326 },
        });

        const outlineSymbol = new SimpleFillSymbol({
          color: [0, 0, 0, 0], // Transparent fill
          outline: new SimpleLineSymbol({
            color: [255, 0, 0], // outline color
            width: 2, // This width can be dynamic or static
          }),
        });

        const outlineGraphic = new Graphic({
          geometry: outlinePolygon,
          symbol: outlineSymbol,
        });

        graphicsLayer.add(outlineGraphic);

        // Add 3D building floors and slices
        for (let i = 0; i < floorsHeight; i++) {
          const altitude = absoluteQuotas[i];
          const floorHeight =
            heightFloors[i] - (i < floorsHeight - 1 ? sliceHeight / 2 : 0); // Reduce height for slice
          const color = colors[i % colors.length];

          // Create the main floor polygon
          const floorPolygon = new Polygon({
            rings: convertedObjects.map((ring) => [ring[0], ring[1], altitude]),
            spatialReference: { wkid: 4326 },
          });

          const floorSymbol = new PolygonSymbol3D({
            symbolLayers: [
              new ExtrudeSymbol3DLayer({
                material: { color: color },
                size: floorHeight,
              }),
            ],
          });

          const floorGraphic = new Graphic({
            geometry: floorPolygon,
            symbol: floorSymbol,
            attributes: {
              buildingName: buildingName,
              floor: i + 1,
            },
          });

          graphicsLayer.add(floorGraphic);

          // Add slice (gap) between floors if it's not the last floor
          if (i < floorsHeight - 1) {
            const sliceAltitude = altitude + floorHeight;

            const slicePolygon = new Polygon({
              rings: convertedObjects.map((ring) => [
                ring[0],
                ring[1],
                sliceAltitude,
              ]),
              spatialReference: { wkid: 4326 },
            });

            const sliceSymbol = new PolygonSymbol3D({
              symbolLayers: [
                new ExtrudeSymbol3DLayer({
                  material: { color: "#FFFFFF" }, // Color of the slice, e.g., white
                  size: sliceHeight, // Set the slice height
                }),
              ],
            });

            const sliceGraphic = new Graphic({
              geometry: slicePolygon,
              symbol: sliceSymbol,
              attributes: {
                buildingName: buildingName,
                slice: i + 1,
              },
            });

            graphicsLayer.add(sliceGraphic);
          }
        }
      }

      buildings?.forEach((building) => {
        addBuildingWithSpaces(building);
      });
    }
  }, [buildings, accessData]);

  if (accessData === null) {
    return <div>Loading...</div>; // Show a loading state while checking access
  }

  if (!accessData) {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg">
        <div className="card-body text-center">
          <h1 className="display-4 text-danger">
            <i className="bi bi-exclamation-triangle-fill"></i>
          </h1>
          <h2 className="card-title">Access Denied</h2>
          <p className="card-text">
            You don't have the necessary permissions to view this content.
          </p>
        </div>
      </div>
    </div>
    );
  }

  return <div id="viewDiv" style={{ height: "100vh", width: "100%" }}></div>;
};

export default EsriMap;
